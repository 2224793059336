@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import "./tailwind.css";

body {
    font-family: 'Nunito', sans-serif;
}


.seat-buttons {
    @apply flex lg:space-x-[3px] space-x-[0.5px] justify-center
}

.seat-button {
    @apply lg:w-[1.4rem] w-[8px] h-[8px] lg:h-[1.4rem] hover:!bg-blue-500 hover:!text-white shrink-0 lg:mb-4 mb-1 lg:text-[10px] text-[5px] lg:rounded border border-gray-400 lg:font-bold font-medium flex items-center justify-center
    disabled:opacity-30 disabled:hover:!bg-white disabled:hover:!text-black
}

.seat-row {
    @apply lg:text-[11px] text-[6px] rounded-full text-white bg-red-500 lg:w-6 lg:h-6 w-[9px] h-[9px] items-center flex justify-center
}


.ticket-booking {
    @apply border border-gray-600 fixed inset-0 bg-gray-900/70 opacity-0 invisible
}

.ticket-booking--open {
    @apply opacity-100 visible
}
